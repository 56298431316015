import { modalAlert } from "Components/Modals/ModalAlert";
import { isInRole } from "helpers/auth";
import { decimalValue } from "helpers/decimalsNumber";
import { getErrors } from "helpers/error";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import * as api from "services/api";
import { Callback } from "Types/Callback";

import {
	ActionPayload,
	ActionTypes,
	IAccountHolderTransaction
} from "../types";

export interface IAccountHolderTransactionRequest {
	user_id: string;
	value: number;
	balanceAccountTypeId: number;
	type: number;
	description?: string;
}

export interface IAccountHolderTransactionImportRequest {
	document: string;
	value: string;
	balanceAccountType: string;
	type: number;
	description?: string;
}

interface IState {
	treasuryAccountTransactionCreate: IAccountHolderTransaction;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export const createTransaction =
	(
		{
			user_id,
			value,
			type,
			balanceAccountTypeId,
			description
		}: IAccountHolderTransactionRequest,
		callback?: Callback<IAccountHolderTransaction>
	) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.CREATE_ACCOUNT_HOLDER_TRANSACTION_REQUEST });

		try {
			const { login } = getState();

			const model = {
				value: decimalValue(value),
				balanceAccountTypeId,
				type,
				description
			};

			const response = await api.sendPost(
				`/User/${user_id}/BalanceAccount/Transfer`,
				model,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: ActionTypes.CREATE_ACCOUNT_HOLDER_TRANSACTION_SUCCESS,
					payload: { item: result.model }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ActionTypes.CREATE_ACCOUNT_HOLDER_TRANSACTION_FAILED
			});
		}
	};

export const importTransactions =
	(
		id: string,
		data: IAccountHolderTransactionImportRequest[],
		sendAt?: string,
		callback?: Callback<IAccountHolderTransaction>
	) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/Business/${id}/BalanceAccount/Transfer/Import${
					sendAt ? `?sendAt=${sendAt}` : ""
				}`,
				data.map(c => ({ ...c })),
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_SUCCESS,
					payload: { item: result.model }
				});
			}

			callback && callback(getErrors(result.errors));

			return dispatch({
				type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED
			});
		} catch (error) {
			console.log(error);

			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});

			return dispatch({
				type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED
			});
		}
	};

export const importTransactionsMultipleCNPJ =
	(
		id: string,
		data: IAccountHolderTransactionImportRequest[],
		treasuryAccountBalance: number,
		callback?: Callback<any>
	) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_REQUEST });

		try {
			const { login } = getState();
			const isAdmin = isInRole(login.user, ["admin"]);
			const response = await api.sendPost(
				`/Business/${id}/BalanceAccount/MultipleCnpj`,
				data.map(c => ({ ...c })),
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (Number(response.status) === 404) {
				callback && callback(ls.error404);
				return dispatch({
					type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED
				});
			}

			let result = await response.json();

			if (result.isValid) {
				const missingValue = result?.model?.reduce(
					(a, b) => a + b.missingValue,
					0
				);
				const headquartersMissingValue =
					result?.params?.headquarterMissingValue || 0;

				if (
					result.params &&
					isAdmin &&
					headquartersMissingValue > treasuryAccountBalance
				) {
					const cnpjsMissingValue: any[] = result.model
						.filter(
							i => i.business.treasuryAccountBalance !== i.totalRequiredValue
						)
						.map(i => ({
							cnpj: i.business.cnpj,
							missingValue:
								i.business.treasuryAccountBalance - i.totalRequiredValue
						}));
					callback &&
						callback(ls.errorInsuficientFoundHeadquarters, null, {
							cnpjsMissingValue,
							headquartersMissingValue:
								headquartersMissingValue - treasuryAccountBalance
						});

					return dispatch({
						type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED
					});
				}
				callback && callback(null, result.model, result.params);
				return dispatch({
					type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_SUCCESS,
					payload: { item: result.model }
				});
			}
			callback && callback(getErrors(result.errors));
			return dispatch({
				type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED
			});
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED
			});
		}
	};
